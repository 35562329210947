import fetch from 'auth/FetchInterceptor';

const AuthService = {};

AuthService.login = function (data) {
  return fetch({
    url: '/auth/login',
    method: 'post',
    data: data
  });
};

AuthService.register = function (data) {
  return fetch({
    url: '/auth/register',
    method: 'post',
    data: data
  });
};

AuthService.logout = function () {
  return fetch({
    url: '/auth/logout',
    method: 'post'
  });
};

AuthService.loginInOAuth = function () {
  return fetch({
    url: '/auth/loginInOAuth',
    method: 'post'
  });
};

AuthService.profile = function () {
  return fetch({
    url: '/user/details',
    method: 'GET'
  });
};

AuthService.resetUserPassword = function (data) {
  return fetch({
    url: '/user/password/reset',
    method: 'put',
    data
  });
};

export default AuthService;
